<template>
  <div class="columns is-gapless is-centered is-mobile px-4 pt-6">
    <div class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd">
      <div class="container has-text-centered pt-6">
        <div v-if="showBackToGame">
          <div class="is-size-5 has-text-weight-semibold mb-3">
            {{ t('You are currently in') }}<br>
            "{{ adventureName }}"
          </div>
          <div @click="$router.push({ name: 'Stage' })" class="button is-primary has-text-weight-semibold">
            {{ t('Click here to join') }}
          </div>
          <div class="has-text-grey-light mt-5 mb-4">
            {{ t('- OR -') }}
          </div>
        </div>
        <div>
          <div class="is-size-5 has-text-weight-semibold mb-1">
            {{ t('Enter a game code') }}
          </div>
          <FormInput class="mb-1" :size="'medium'" :type="'text'" :placeholder="''" :value="gameCode" :capsOnly="true" v-on:update="gameCode = $event" :centered="true"/>
          <!-- HACK for twofeet -->
          <button
          @click="joinGame()"
          class="button is-fullwidth has-text-weight-semibold is-primary is-medium mt-0"
          :disabled="!gameCode || (gameCode && gameCode.length < 4 && gameCode.toUpperCase() !== 'PERTH' && gameCode.toUpperCase() !== 'BOORLOO' && gameCode.toUpperCase() !== 'HERITAGE')"
          >
          {{ t('Go') }}
          </button>
        </div>
        <div class="has-text-grey-light mt-5 mb-4">
          {{ t('OR') }}
        </div>
        <div class="is-size-5 has-text-weight-semibold mb-1">
          {{ t('Scan a game QR code') }}
        </div>
        <!-- <div class="is-size-7 mb-2">
          Only 1 person in each team needs to do this. <br> Once you create a team, you will get a link to share with your teammates.
        </div> -->
        <FormInput :size="'medium'" :type="'qrbarcode'" :value="scannedLink" v-on:update="scannedLink = $event"/>
      </div>
      <div class="container has-text-centered mt-6">
        <div class="has-text-grey-light is-size-7 mb-2">
          {{ t(`App version: ${appVersion}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/forms/FormInput'

export default {
  name: 'Start',
  components: {
    FormInput
  },
  data () {
    return {
      scannedLink: null,
      gameCode: null
    }
  },
  methods: {
    joinGame () {
      // HACK - to be deleted after May 2024 - Mo Afiq
      if (this.gameCode.toUpperCase().trim() === 'PERTH') this.gameCode = 'GSKI'
      if (this.gameCode.toUpperCase().trim() === 'BOORLOO') this.gameCode = 'EZJY'
      if (this.gameCode.toUpperCase().trim() === 'HERITAGE') this.gameCode = 'CHLB'

      if (this.gameCode && this.gameCode.length === 4) {
        window.location.href = `${window.location.origin}/p/${this.gameCode.toUpperCase().trim()}`
      } else if (this.gameCode && this.gameCode.length > 4) {
        window.location.href = `${window.location.origin}/lobby/${this.gameCode.replace(' ', '-').toLowerCase().trim()}`
      } else {
        alert('Invalid code, please try again.')
      }
    }
  },
  computed: {
    adventureName (){
      return this.$store.state.adventureName
    },
    appVersion () {
      return this.$store.state.appVersion
    },
    showBackToGame (){
      return (
        this.adventureName && this.adventureName.length > 0 && this.$store.state.userName && this.$store.state.userName.length > 0
        // &&
        // this.$store.state.adventureNameDateString && this.$store.state.adventureNameDateString === (new Date).toLocaleDateString()
      )
    }
  },
  watch: {
    scannedLink (newScannedLink, oldScannedLink) {
      if (
        newScannedLink &&
        !oldScannedLink
        // && newScannedLink.includes(window.location.origin)
        ) {
          window.location.href = newScannedLink.replace('/scan/', '/play/')
      }
    },
    gameCode (newGameCode, oldGameCode) {
      if (
        !oldGameCode &&
        newGameCode.includes(window.location.origin)
      ) {
        this.gameCode = this.gameCode.replace(window.location.origin, '')
      }
    }
  },
  mounted () {
    document.title = `PlayTours | Teambuilding & Event Engagement`
  }
}
</script>

<style>

</style>
